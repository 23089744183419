<template>
  <div>
    <section class="section">
      <div class="container">
        <h1 class="has-text-centered is-size-1">Geminio</h1>
        <div class="content pt-4">
          <p>
            Geminio is a web application to share files with your family, friends or colleagues. Simply upload a file and share the generated link.
            Those that have received the link are able to view and download the shared file.
          </p>

          <upload-file/>
        </div>
      </div>
    </section>
    <hr>
    <section class="section">
      <div class="container">
        <h2 class="has-text-centered is-size-2">How it works</h2>
        <div class="columns pt-4">
          <div class="column is-one-third is-full-mobile">
            <div class="card h-100">
              <div class="card-image">
                <div class="card-icon">
                  <i class="fas fa-cloud-upload-alt"></i>
                </div>
              </div>
              <div class="card-content">
                <div class="content has-text-centered">
                  Step 1: Upload a file.
                </div>
              </div>
            </div>
          </div>

          <div class="column is-one-third is-full-mobile">
            <div class="card h-100">
              <div class="card-image">
                <div class="card-icon">
                  <i class="fas fa-share-alt"></i>
                </div>
              </div>
              <div class="card-content">
                <div class="content has-text-centered">
                  Step 2: Share the unique URL of the file with your family, friends or colleagues.
                </div>
              </div>
            </div>
          </div>

          <div class="column is-one-third is-full-mobile">
            <div class="card h-100">
              <div class="card-image">
                <div class="card-icon">
                  <i class="fas fa-cloud-download-alt"></i>
                </div>
              </div>
              <div class="card-content">
                <div class="content has-text-centered">
                  Step 3: Those in possession of the URL can now view and download the file.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr>
    <section class="section">
      <div class="container">
        <h2 class="has-text-centered is-size-2">Find a file</h2>
        <div class="columns is-centered pt-4 pb-4">
          <div class="column is-two-thirds is-full-mobile">
            <form @submit.prevent="searchFile">
              <div class="field is-grouped">
                <p class="control is-expanded">
                  <input v-model="searchSlug" class="input" type="text" placeholder="Type in slug of file">
                </p>
                <p class="control">
                  <a class="button is-primary" @click="searchFile">
                    Find file
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../config";
import UploadFile from "../components/UploadFile";

export default {
  name: "LandingPage",
  components: {UploadFile},
  computed: {
    ...mapGetters(['isLoggedIn', 'hasAccessToDomain'])
  },
  data() {
    return {
      searchSlug: ''
    }
  },
  methods: {
    searchFile() {
      if (!this.searchSlug) return;

      this.$router.push({ name: 'ViewFile', params: { slug: this.searchSlug } });
    }
  }
}
</script>
