<template>
  <div>
    <template v-if="isLoggedIn">
      <div class="button is-primary" :disabled="!hasAccessToDomain" @click="$refs.file.click()">
        <span class="icon">
          <i class="fas fa-cloud-upload-alt"></i>
        </span>
        <span>Upload a file</span>
      </div>
      <input type="file" ref="file" style="display: none" :disabled="!hasAccessToDomain" @change="uploadFile">
    </template>

    <div class="button is-primary" v-else @click="login">
      Login to upload a file
    </div>

    <upload-result-modal :file="uploadedFile" v-if="showModal" @close="showModal = false"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toast } from "bulma-toast";
import UploadResultModal from "./modals/UploadResultModal";
import config from "../config";

export default {
  name: "UploadFile",
  components: {UploadResultModal},
  computed: {
    ...mapGetters(['isLoggedIn', 'hasAccessToDomain'])
  },
  data() {
    return {
      uploadedFile: null,
      showModal: false
    }
  },
  methods: {
    login() {
      window.location.href = `${config.ssoUrl}?redirect=${window.location.href}`;
    },
    uploadFile(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.$store.commit('setIsLoading', true);
      const formData = new FormData();
      formData.append('file', file);
      this.$http.post('/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.uploadedFile = res.data.data;
        this.showModal = true;
        this.$store.commit('setIsLoading', false);
        toast({
          message: 'File successfully uploaded.',
          type: 'is-dark',
          duration: 3000,
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
        this.$emit('uploaded');
      }).catch(error => {
        this.$store.commit('setIsLoading', false);
        toast({
          message: `An error occurred: ${error.message}.`,
          type: 'is-danger',
          duration: 3000,
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
