<template>
  <div class="modal is-active">
    <div class="modal-background" @click="onClose"></div>
    <div class="modal-content">
      <div class="box">
        <p>File successfully uploaded.</p>
        <copy-input label="Link" :value="url" @copy="onCopyUrl"/>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="onClose"></button>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import CopyInput from "@/components/CopyInput";
import util from "@/util";

export default {
  name: "UploadResultModal",
  components: {CopyInput},
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    url() {
      return util.urlOf(this.file);
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onCopyUrl() {
      toast({
        message: 'The link has been copied to your clipboard.',
        type: 'is-dark',
        duration: 3000,
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    }
  }
}
</script>
