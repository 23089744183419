<template>
  <div class="field is-horizontal pt-4">
    <div class="field-label is-normal" v-if="label">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded has-icons-right" @click="copyValue">
          <input :value="value" class="input slug-input" type="text" readonly>
          <span class="icon is-small is-right">
            <i class="fas fa-copy"></i>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyInput",
  props: {
    label: {
      type: String,
      required: false,
      default: () => null
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    copyValue() {
      const dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.value = this.value;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.$emit('copy');
    }
  }
}
</script>

<style lang="scss" scoped>
.slug-input {
  cursor: pointer;
  background-color: whitesmoke;
  border-color: whitesmoke;
  box-shadow: none;
  color: #7a7a7a;
}
</style>
